import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_INC_EMAILS } from "../../../../utils/GraphQL/queries";
import "./emails.css";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import EmailModal from "../../adminComponents/EmailModal/EmailModal";
import SinglePictureLookup from "../../adminComponents/SinglePictureLookup/SinglePictureLookup";
import { Snackbar, useMediaQuery } from "@mui/material";
import {
  ADD_BLOCKED_EMAIL,
  ARCHIVE_EMAIL,
  DELETE_EMAIL,
  ARCHIVE_EMAIL_CLEANUP,
} from "../../../../utils/GraphQL/mutations";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import ArchiveIcon from "@mui/icons-material/Archive";
import ReplyIcon from "@mui/icons-material/Reply";
import InboxIcon from "@mui/icons-material/Inbox";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import SendEmailOpenBtn from "../../adminComponents/SendEmail/SendEmailOpenBtn";
import SendEmailModal from "../../adminComponents/SendEmail/SendEmailModal";
import EmailFlags from "../../adminComponents/EmailFlags/EmailFlags";

const AdminEmails = () => {
  const [emailOpenId, setEmailOpenId] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [pageControl, setPageControl] = useState({
    totalDoc: 0,
    pageNum: 1,
    highestPage: 1,
    limit: 40,
    loading: false,
  });
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [openSendReply, setOpenSendReply] = useState(false);
  const [sendReplyObj, setSendReplyObj] = useState(false);
  const [picLookupOpen, setPicLookupOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [emailsLoading, setEmailsLoading] = useState(true);
  const [getArchived, setGetArchived] = useState([false, null, undefined]);
  const [getInbox] = useLazyQuery(GET_INC_EMAILS);
  const [archiveEmail] = useMutation(ARCHIVE_EMAIL);
  const [blockEmailCall] = useMutation(ADD_BLOCKED_EMAIL);
  const [deleteEmail] = useMutation(DELETE_EMAIL);
  const [archiveCleanupCall] = useMutation(ARCHIVE_EMAIL_CLEANUP);
  const smaller600 = useMediaQuery("(max-width:600px)");
  const [emails, setEmails] = useState([]);
  const [emailOriginal, setEmailOriginal] = useState([]);
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setEmails([...emailOriginal]);
    handleSearchInput();
    // eslint-disable-next-line
  }, [emailOriginal]);

  useEffect(() => {
    if (!openSearch && searchInput?.length === 0) {
      setEmails([...emailOriginal]);
    }
    // eslint-disable-next-line
  }, [openSearch, searchInput]);

  useEffect(() => {
    getSelectedEmails();
    // eslint-disable-next-line
  }, [pageControl?.pageNum, pageControl?.limit, openSearch]);

  useEffect(() => {
    if (pageControl?.pageNum !== 1) {
      setPageControl((prev) => ({
        ...prev,
        pageNum: 1,
      }));
      return;
    }

    getSelectedEmails();
    // eslint-disable-next-line
  }, [getArchived]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (emailModalOpen === true) {
      body.style.overflow = "hidden";
    }
    if (emailModalOpen === false) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailModalOpen]);

  // useEffect(() => {
  // }, [emails]);

  const getSelectedEmails = async () => {
    const { data } = await getInbox({
      variables: {
        getArchived: getArchived,
        page: pageControl?.pageNum?.toString(),
        limit: pageControl?.limit?.toString(),
        searching: openSearch,
      },
      fetchPolicy: "network-only",
    });

    const totalCount = parseInt(data?.getIncEmails?.totalDoc);

    if (data?.getIncEmails?.emails) {
      setEmailOriginal([...data?.getIncEmails?.emails]);
    }

    setPageControl((prev) => ({
      ...prev, 
      totalDoc: totalCount,
      highestPage: Math.floor(totalCount / prev?.limit) + 1,
      loading: false,
    }));
    setEmailsLoading(false);
  };

  const handlePage = (page) => {
    let newPage = 1;

    if (typeof page === "number") {
      setPageControl((prev) => ({
        ...prev,
        pageNum: 1,
        limit: page,
      }));
      return;
    }

    if (page === "up" && pageControl?.pageNum < pageControl?.highestPage) {
      newPage = pageControl?.pageNum + 1;
    }

    if (page === "up" && pageControl?.pageNum >= pageControl?.highestPage) {
      newPage = pageControl?.highestPage;
    }

    if (page === "down" && pageControl?.pageNum > 1) {
      newPage = pageControl?.pageNum - 1;
    }

    if (page === "down" && pageControl?.pageNum <= 1) {
      newPage = 1;
    }

    setPageControl((prev) => ({
      ...prev,
      pageNum: newPage,
    }));
  };

  const openEmail = (id) => {
    setEmailOpenId(id);
    setEmailModalOpen(true);
  };

  const changeParentStyle = (bool) => {
    setSendModalOpen(bool);
  };

  const prepareReplyEmail = (emailObj) => {
    setSendReplyObj({
      to: emailObj?.from,
      subject: `Re: ${emailObj?.emailSubject}`,
    });

    if (emailModalOpen) {
      closeEmail();
    }

    setOpenSendReply(true);
  };

  const changeParentForEmailModal = (bool) => {
    setOpenSendReply(bool);
  };

  const setArchiveRetrieval = (showArchived) => {
    const search = showArchived ? [true] : [false, null, undefined];
    setGetArchived(search);
    return;
  };

  const closeEmail = (event) => {
    setEmailOpenId("");
    setEmailModalOpen(false);
    getSelectedEmails();
  };

  const handleSearchInput = (input) => {
    const value = typeof input === "string" ? input : searchInput;
    setSearchInput(value);

    if (typeof input === "string" && input?.length === 0) {
      setEmails([...emailOriginal]);
      return;
    }

    const regex = RegExp(value.trim(), "i");

    const foundEmails = emailOriginal?.slice()?.filter((emlObj) => {
      if (regex.test(emlObj?.from) || regex.test(emlObj?.emailSubject)) {
        return true;
      }
      return false;
    });

    setEmails([...foundEmails]);
  };

  const handleArchive = async (emailId) => {
    const { data: archived } = await archiveEmail({
      variables: { emailId: emailId, changeArchive: true },
    });

    if (archived?.archiveEmail) {
      handleSnack(
        <div className="email-archive-snack">
          <span className="email-archive-item-snack">Email Archived</span>
          <button
            className="email-archive-snack-btn"
            onClick={() => {
              handleRestoreArchive(emailId);
            }}
          >
            <RestorePageIcon sx={{ fontSize: "20px", color: "#05F842" }} />
            (Restore?)
          </button>
        </div>,
        8000
      );
      getSelectedEmails();
    }
    return;
  };

  const handleRestoreArchive = async (emailId) => {
    const { data: archived } = await archiveEmail({
      variables: { emailId: emailId, changeArchive: false },
    });

    if (archived?.archiveEmail) {
      handleSnack(
        <div className="email-archive-snack">
          <span className="email-archive-item-snack">Email Restored </span>
          <CheckIcon sx={{ fontSize: "20px", color: "#05F842" }} />
        </div>,
        2500
      );
      getSelectedEmails();
    }
    return;
  };

  const handleDeleteEmail = async (emailId) => {
    const { data: deleted } = await deleteEmail({
      variables: { emailId: emailId },
    });

    if (deleted?.deleteEmailById) {
      handleSnack(
        <div className="email-archive-snack">
          <span className="email-archive-item-snack">Email Deleted </span>
          <CheckIcon sx={{ fontSize: "20px", color: "#05F842" }} />
        </div>,
        2000
      );
      getSelectedEmails();
    }
    return;
  };

  const archiveCleanup = async () => {
    const { data } = await archiveCleanupCall({
      variables: {
        current: Date.now().toString(),
      },
    });

    if (data?.archivedEmailCleanup) {
      handleSnack(
        `Archives Cleaned Up. Deleted: ${data?.archivedEmailCleanup}`,
        2000
      );
      getSelectedEmails();
    }

    return;
  };

  const blockEmailSnack = (email) => {
    const strippedEmail = email.split("<").pop().split(">")[0].trim();
    // <CheckIcon sx={{ fontSize: "20px", color: "#05F842" }} />

    handleSnack(
      <div className="email-archive-snack">
        <span>Block {strippedEmail}?</span>
        <button
          className="email-page-action-img-button"
          onClick={(event) => {
            event.stopPropagation();
            blockEmail(email);
            handleSnackClose();
          }}
        >
          <CheckIcon
            sx={{ fontSize: "20px", color: "#05F842" }}
            titleAccess="Yes"
          />
        </button>
        <button
          className="email-page-action-img-button"
          onClick={(event) => {
            event.stopPropagation();
            handleSnackClose();
          }}
        >
          <BlockIcon
            sx={{ fontSize: "20px", color: "#c80404" }}
            titleAccess="No"
          />
        </button>
      </div>,
      6000
    );
  };

  const blockEmail = async (email) => {
    const { data } = await blockEmailCall({
      variables: {
        incEmail: email,
      },
    });

    if (data?.addBlockedEmail) {
      handleSnack(
        <div className="email-archive-snack">
          <span className="email-archive-item-snack">
            Email Blocked. {data?.addBlockedEmail?.emailsReceived} Emails
            Deleted
          </span>
          <CheckIcon sx={{ fontSize: "20px", color: "#05F842" }} />
        </div>,
        3000
      );

      getSelectedEmails();
      return;
    }

    handleSnack("Something went wrong...", 2000);
    return;
  };

  const loadingHolders = () => {
    return Array.from({ length: 15 }).map((_, index) => (
      <div key={index + Date.now()} className="email-item-wrapper">
        <div className="email-item-loading">
          <div>
            <div>
              <span className="email-item-holders-loading">From:</span>
            </div>
            <div className="email-item-small-date">
              <span className="email-item-date-loading">Date:</span>
            </div>
          </div>
          <div>
            <span className="email-item-holders-loading">Subject:</span>
          </div>
          <div className="email-has-attach loading">att: 0</div>
        </div>
      </div>
    ));
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  return (
    <div className="email-page">
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      {emailModalOpen && (
        <EmailModal
          prepareReply={prepareReplyEmail}
          emailId={emailOpenId}
          closeEmail={closeEmail}
          changeEmail={openEmail}
        />
      )}

      {/* Send email modal for replies */}
      {openSendReply && (
        <SendEmailModal
          handleSnack={handleSnack}
          changeParent={changeParentForEmailModal}
          templateObj={sendReplyObj}
        />
      )}

      {picLookupOpen && <SinglePictureLookup />}
      <div
        className={
          sendModalOpen
            ? "email-page-container"
            : "email-page-container email-page-filter"
        }
      >
        <h2>{getArchived[0] ? "Viewing Archived" : "Viewing Inbox"}</h2>
        {pageControl?.totalDoc > 0 && (
          <div className="email-page-page-box">
            <span style={{ margin: "0px 5px" }}>
              {openSearch ? emails?.length : pageControl?.totalDoc}{" "}
              {openSearch
                ? emails?.length > 1
                  ? "Emails"
                  : "Email"
                : pageControl?.totalDoc > 1
                ? "Emails"
                : "Email"}{" "}
              {!openSearch && (
                <>
                  - Page {pageControl?.pageNum} of {pageControl?.highestPage}
                </>
              )}
            </span>
            {!openSearch && (
              <>
                {pageControl?.pageNum !== 1 && (
                  <div className="email-page-page-control-button-box">
                    <button
                      disabled={pageControl?.loading}
                      className="email-page-page-control-button"
                      onClick={() => {
                        setPageControl((prev) => ({ ...prev, loading: true }));
                        handlePage("down");
                      }}
                    >
                      <strong>-</strong>
                    </button>
                  </div>
                )}
                {pageControl?.pageNum !== pageControl?.highestPage && (
                  <div className="email-page-page-control-button-box">
                    <button
                      disabled={pageControl?.loading}
                      className="email-page-page-control-button"
                      onClick={() => {
                        setPageControl((prev) => ({ ...prev, loading: true }));
                        handlePage("up");
                      }}
                    >
                      <strong>+</strong>
                    </button>
                  </div>
                )}
                <span>- Per Page: </span>
                <button
                  disabled={pageControl?.loading}
                  className={
                    pageControl?.limit === 20
                      ? "email-page-page-control-limit green-text"
                      : "email-page-page-control-limit"
                  }
                  onClick={() => {
                    setPageControl((prev) => ({ ...prev, loading: true }));
                    handlePage(20);
                  }}
                >
                  20
                </button>
                <button
                  disabled={pageControl?.loading}
                  className={
                    pageControl?.limit === 40
                      ? "email-page-page-control-limit green-text"
                      : "email-page-page-control-limit"
                  }
                  onClick={() => {
                    setPageControl((prev) => ({ ...prev, loading: true }));
                    handlePage(40);
                  }}
                >
                  40
                </button>
                <button
                  disabled={pageControl?.loading}
                  className={
                    pageControl?.limit === 60
                      ? "email-page-page-control-limit green-text"
                      : "email-page-page-control-limit"
                  }
                  onClick={() => {
                    setPageControl((prev) => ({ ...prev, loading: true }));
                    handlePage(60);
                  }}
                >
                  60
                </button>
              </>
            )}
          </div>
        )}
        <div className="email-actions-button-box">
          {getArchived[0] && (
            <button
              className="email-archive-button-show"
              onClick={archiveCleanup}
            >
              Cleanup Archives
            </button>
          )}
          <SendEmailOpenBtn
            changeParentStyle={changeParentStyle}
            sendEmailBtnMes={
              smaller600 ? <SendIcon titleAccess="Send Email" /> : "Send Email"
            }
          />
          <button
            className={
              smaller600
                ? "email-page-action-img-button"
                : "email-archive-button"
            }
            onClick={() => {
              if (openSearch) {
                setSearchInput("");
              }
              setOpenSearch((prev) => !prev);
            }}
          >
            {smaller600 ? (
              <SearchIcon titleAccess="Search Emails" />
            ) : openSearch ? (
              "Close Search"
            ) : (
              "Search Emails"
            )}
          </button>
          <button
            className={
              smaller600
                ? "email-page-action-img-button"
                : "email-archive-button-show"
            }
            onClick={() => {
              setArchiveRetrieval(!getArchived[0]);
              setEmailsLoading(true);
            }}
          >
            {smaller600 ? (
              getArchived[0] ? (
                <InboxIcon titleAccess="View Inbox" />
              ) : (
                <ArchiveIcon titleAccess="View Archived" />
              )
            ) : getArchived[0] ? (
              "Show Inbox"
            ) : (
              "Show Archived"
            )}
          </button>
          <button
            className={"email-page-action-img-button"}
            onClick={() => {
              setPicLookupOpen((prev) => !prev);
            }}
          >
            <CameraAltIcon titleAccess="Picture Lookup" />
          </button>
          <button
            className="email-page-action-img-button"
            onClick={() => {
              setEmailsLoading(true);
              getSelectedEmails();
            }}
          >
            <RefreshIcon titleAccess="Refresh Emails" />
          </button>
        </div>
        {openSearch && (
          <div className="email-page-search-box">
            <div className="email-page-search-input">
              <CssTextFieldStandard
                sx={{ width: "100%", my: 0.5 }}
                type="text"
                label={`Search`}
                name="to"
                inputMode="text"
                autoFocus
                value={searchInput}
                onChange={(event) => {
                  handleSearchInput(event.target.value);
                }}
                variant="standard"
              />
            </div>
          </div>
        )}
        {emailsLoading && loadingHolders()}
        {emails?.length > 0 ? (
          emails?.map((email, index) => {
            let date = undefined;
            const getNum = parseInt(email?.dateCreated);
            if (getNum > 0) {
              date = BAGroupTimeFormat(getNum, true);
            }

            const itemClassName = `${
              email?.emailRead ? "email-item read-email" : "email-item"
            } ${email?.flag === "!" ? "email-item-important" : ""} ${
              email?.flag === "!!" ? "email-item-urgent" : ""
            }`;

            return (
              <div
                className="email-item-wrapper"
                key={"emails-number:" + email?.id + index}
              >
                <div className={itemClassName}>
                  <div className="email-open-btns">
                    <button
                      className="email-info-section"
                      onClick={() => {
                        openEmail(email?.id);
                      }}
                    >
                      <div className="text-overflow-ellipsis">
                        <span className="email-item-holders ">From:</span>{" "}
                        {email?.from}
                      </div>
                      <div className="email-item-small-date">
                        <span className="email-item-date">Date: </span>{" "}
                        {date || "Missing Date"}
                      </div>
                    </button>
                    <button
                      name={email?.id}
                      className="email-info-section"
                      onClick={() => {
                        openEmail(email?.id);
                      }}
                    >
                      <div className="text-overflow-ellipsis">
                        <span className="email-item-date">Subject:</span>{" "}
                        {email?.emailSubject}
                      </div>
                    </button>
                  </div>
                  <div className="email-actions-box">
                    <div
                      className={
                        email?.attachments > 0
                          ? "email-has-attach att-error"
                          : "email-has-attach"
                      }
                    >
                      att: {email?.attachments}
                    </div>
                    <button
                      className="email-archive-button"
                      onClick={(event) => {
                        event.stopPropagation();
                        prepareReplyEmail(email);
                      }}
                    >
                      {smaller600 ? <ReplyIcon titleAccess="Reply" /> : "Reply"}
                    </button>
                    {getArchived[0] ? (
                      <>
                        <button
                          className="email-archive-button"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleRestoreArchive(email?.id);
                          }}
                        >
                          Restore
                        </button>
                        <button
                          className="email-archive-button"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDeleteEmail(email?.id);
                          }}
                        >
                          Delete
                        </button>
                      </>
                    ) : (
                      <button
                        className={
                          smaller600
                            ? "email-page-action-img-button"
                            : "email-archive-button"
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          handleArchive(email?.id);
                        }}
                      >
                        {smaller600 ? (
                          <ArchiveIcon titleAccess="Archive Email" />
                        ) : (
                          "Archive"
                        )}
                      </button>
                    )}
                    {/* Flag component */}
                    <EmailFlags
                      handleSnack={handleSnack}
                      getSelectedEmails={getSelectedEmails}
                      emailId={email?.id}
                    />
                    <button
                      className="email-page-action-img-button"
                      onClick={(event) => {
                        event.stopPropagation();
                        blockEmailSnack(email?.from);
                      }}
                    >
                      <BlockIcon titleAccess="Block Email" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h2 className="page-header">No Emails To Display</h2>
        )}
      </div>
    </div>
  );
};

export default AdminEmails;
